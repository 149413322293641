<template>
  <div class="add">
    <el-dialog
      :title="showStatus === 2 ? '编辑' : '添加'"
      :visible="!!showStatus"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="140px"
        class="form"
        hide-required-asterisk
      >
        <el-row>
          <el-col span="12">
            <el-form-item label="方案名称" prop="planName">
              <el-input placeholder="请输入方案名称" v-model="form.planName"></el-input>
            </el-form-item>
            <el-form-item label="服务费（%）" prop="serviceRate">
              <el-input v-model.number="form.serviceRate" placeholder="请输入服务费（%）"></el-input>
            </el-form-item>
            <el-form-item label="总利率" prop="interestRate">
              <el-input v-model.number="form.interestRate" placeholder="请输入总利率"></el-input>
            </el-form-item>
            <el-form-item label="滞纳金率" prop="overdueDayRate">
              <el-input v-model.number="form.overdueDayRate" placeholder="请输入滞纳金率"></el-input>
            </el-form-item>
            <el-form-item label="续期天数" prop="renewDays">
              <el-input v-model.number="form.renewDays" placeholder="请输入滞纳金率"></el-input>
            </el-form-item>
            <el-form-item label="放款前扣除利息" prop="interestBeforeLoan">
              <el-switch
                v-model="form.interestBeforeLoan"
                active-text="是"
                :active-value="1"
                inactive-text="否"
                :inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col span="12">
            <el-form-item label="分期配置" prop="stagingCount">
              <div style="width: 100%; text-align: right;">
                <el-button size="mini" type="success" @click="change(form.stagingCount + 1)">+</el-button>
                <el-button size="mini" type="danger" @click="change(form.stagingCount - 1)">-</el-button>
              </div>
              
            </el-form-item>
            <el-table class="table" border :data="fenqiData" @cell-click="cellClick">
                <el-table-column type="index" label="ID" align="center"></el-table-column>
                <el-table-column prop="time" label="天数" align="center">
                  <template  slot-scope="scope">
                    <el-input size="small" @blur="loseFcous(scope.$index, scope.row)" v-if="scope.row.seen" v-model="scope.row.time" ></el-input>
                    <span v-else>{{scope.row.time}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="ratio" label="比例 (%)" align="center" >
                  <template  slot-scope="scope">
                    <el-input size="small" @blur="loseFcous(scope.$index, scope.row)" v-if="scope.row.seen" v-model="scope.row.ratio" ></el-input>
                    <span v-else>{{scope.row.ratio}}%</span>
                  </template>
                </el-table-column>
              </el-table>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 参数说明：
 * @showStatus => 弹框状态：0 - 关闭，1 - 添加，2 - 编辑
 * @roleOpts => 角色选项
 * @editData => 被编辑行
 */

import { base, baseUrl } from "@/api/env";
export default {
  props: {
    showStatus: {
      type: Number,
      default: 0
    },
    roleOpts: {
      type: Array,
      default: () => []
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        planName: '',
        stagingCount: '',
        serviceRate: '',
        interestRate: '',
        overdueDayRate: '',
        renewDays: '',
        renewDayRate: 0,
        interestBeforeLoan:0,
      },
      showInput: false,
      fenqiData: [],
      rules: {
        planName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        stagingCount: [{ required: true, message: '分期数不能为空'},
          { type: 'number', message: '分期数必须为数字值'}],
        serviceRate:[{ required: true, message: '服务费不能为空'},
          { type: 'number', message: '服务费必须为数字值'}],
        interestRate:[{ required: true, message: '总利率不能为空'},
          { type: 'number', message: '总利率必须为数字值'}],
        overdueDayRate:[{ required: true, message: '滞纳金率不能为空'},
          { type: 'number', message: '滞纳金率必须为数字值'}],
        renewDays:[{ required: true, message: '续期天数不能为空'},
          { type: 'number', message: '续期天数必须为数字值'}],
        // amountMin:[{ required: true, message: '最小申请金额不能为空'},
        //   { type: 'number', message: '最小申请金额必须为数字值'}],
        // amountMax:[{ required: true, message: '最大申请金额不能为空'},
        //   { type: 'number', message: '最大申请金额必须为数字值'}]
      },
      width: '60%'
    };
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields();

      this.fenqiData = []
      this.$emit('cancel');
    },
    submit() {
      let check = true;
      this.$refs['form'].validate(res => (check = res) );
      this.fenqiData.forEach(item =>{
        delete item.seen
      })
      if(!check) return;
       let ratioAmount = 0
      for (let i = 0; i < this.fenqiData.length; i++) {
        ratioAmount += Number(this.fenqiData[i].ratio)
      }
      if(ratioAmount !== 100){
        this.$message.error('分期配置比例相加需等于100！');
        return false
      }
      this.form.stagingDays = JSON.stringify(this.fenqiData)
      // this.form.stagingDays = JSON.stringify(this.fenqiData)
      this.fenqiData = []

       this.$emit('submit-form', this.form);
        this.$refs['form'].resetFields();
    },
    openDialog() {
      if(document.documentElement.clientWidth < 480){
        this.width ='95%'
      }
      // 填充编辑表单数据
      if (this.showStatus === 2) {
        let Arr = JSON.parse(this.editData.stagingDays)
        Arr.forEach(item => {
          item.seen = false
        });
        this.fenqiData = Arr
        this.$nextTick(() => {
          delete this.editData.createAt
          delete this.editData.updateAt
          delete this.editData.tempId
          let {form={}} = this
          form = this.editData
          this.form = form

          // this.form.amountMax = (this.form.amountMax / 100.).toFixed(2)
          // this.form.amountMin = (this.form.amountMin / 100.).toFixed(2)
          // this.alipayQrcode = this.editData.alipayQrcode
          // this.imageID = this.editData.wechatQrcode

          // this.alipayUrl = base+'api/common/attach/'+this.editData.alipayQrcode
          // this.imageUrl = base+'api/common/attach/'+ this.editData.wechatQrcode
        });
      }
    },
    loseFcous(index, row) {
      row.seen=false;
    },
    cellClick(row, column) {
        row.seen=true;
    },
    change(n){
      this.form.stagingCount = Math.max(0, n);
      // noinspection EqualityComparisonWithCoercionJS
      while (this.form.stagingCount != this.fenqiData.length) {
        if (this.form.stagingCount > this.fenqiData.length)
          this.fenqiData.push({"time":0, "ratio":0,'seen':false})
        else
          this.fenqiData.pop();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add {
  .form {
    width: 85%;
    text-align: left;
    margin: 0 auto;
  }
  .el-form-item__label{
    width: 140px!important;
  }
  .el-form-item__content{
    margin-left: 140px;
  }
}
 .pic_list_box {
    display: flex;
    border: 1px dashed #d9d5d5;
    height: 160px;
    width: 300px;
    .upload_btn {
      width: 80px;
      height: 80px;
      padding-left: 15px;
    }

    .pic_list {
      display: flex;
      img {
        width: 280px;
        height: 160px;
        padding-left: 15px;
        padding-bottom: 10px;
      }
    }
  }
  .table{
    float: right;
    width: 80%;
  }
  .tableBox{
    width: 85%;
    text-align: left;
    position: relative;
      right: 0;
    .input-box {
      display: none
    }
    .current-cell .input-box {
      display: block;
      margin-left: -15px;
    }
  }
  .input-box {
      display: none
    }
    .current-cell .input-box {
      display: block;
      margin-left: -15px;
    }
@media screen and (max-width: 480px) {

    .el-col-12{
      width: 100%;
    }
    .table{
      margin-left: 0!important;
    }
}
</style>
